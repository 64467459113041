import type { WishlistPayload, Wishlist } from '@yescapa-dev/ysc-api-js/legacy'
import { WISHLIST_ADD_TO_LIST_EVENT } from '~/utils/analytics/wishlists'

export const useWishlistsStore = defineStore('wishlists', () => {
  const { $api } = useYscApi()

  const current = ref<{
    header: {
      name: string | null
    }
    wishlist: Wishlist | null
  }>({
    header: {
      name: null,
    },
    wishlist: null,
  })
  const all = ref<Wishlist[]>([])

  const isCamperInWishlist = computed(() => (id: number) => !!all.value.find(list => list.ad_set.find(ad => ad.id === id)))

  const fetchWishlists = async ({ refresh }: { refresh?: boolean } = { refresh: false }) => {
    const { isLoggedIn } = storeToRefs(useUserStore())
    if (!isLoggedIn.value || (all.value.length > 0 && !refresh)) {
      return Promise.resolve()
    }
    all.value = await $api.wishlists.getAll()
  }

  const fetchWishlist = async ({ slug, refresh }: { slug: string, refresh?: boolean }) => {
    if (!refresh && slug === current.value.wishlist?.slug) {
      return Promise.resolve()
    }
    const data = await $api.wishlists.getBySlug(slug)
    current.value.header.name = data.name
    current.value.wishlist = data
  }

  const updateOrCreateWishlist = async ({ form, create = false }: { form: WishlistPayload, create?: boolean }) => {
    let data: Wishlist | undefined
    if (create) {
      data = await $api.wishlists.create(form)
      all.value.push(data)
    }
    else if (current.value.wishlist) {
      data = await $api.wishlists.update(current.value.wishlist.slug, form)
      current.value.wishlist = data
      current.value.header.name = data.name
    }
    return data
  }

  const updateWishlistSet = ({ wishlistId, ad_set }: { wishlistId: number, ad_set: Wishlist['ad_set'] }) => {
    const wishlistIndex = all.value.findIndex(w => w.id === wishlistId)
    all.value[wishlistIndex].ad_set = ad_set
  }

  const addToWishlist = async ({ slug, product_id }: { slug: string, product_id: number }) => {
    const { event } = useGtag()
    event(WISHLIST_ADD_TO_LIST_EVENT)
    const data = await $api.wishlists.addProductToWishlist(slug, { product_id: product_id.toString() })
    updateWishlistSet({ wishlistId: data.id, ad_set: data.ad_set })
  }

  const removeFromWishlist = async ({ slug, product_id }: { slug: string, product_id: number }) => {
    await $api.wishlists.deleteProductFromWishlist(slug, { product_id: product_id.toString() })
  }

  return {
    current,
    all,
    isCamperInWishlist,
    fetchWishlists,
    fetchWishlist,
    updateOrCreateWishlist,
    addToWishlist,
    removeFromWishlist,
  }
})
