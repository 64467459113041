<script setup lang="ts">
import type { WishlistPayload } from '@yescapa-dev/ysc-api-js/legacy'
import type { SubmitAction } from '~/types/commons'

interface Props {
  name?: string
  isPublic?: boolean
  submitAction: SubmitAction<WishlistPayload>
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  isPublic: false,
})

const { t } = useI18n()

const form = ref<WishlistPayload>({
  name: props.name,
  is_public: props.isPublic,
})

watchEffect(() => {
  form.value.name = props.name
  form.value.is_public = props.isPublic
})

const isPublicOptions = [
  { value: true, text: t('data.wishlist.is_public.options.public') },
  { value: false, text: t('data.wishlist.is_public.options.private') },
]

defineEmits<{
  close: []
}>()

const { sendingForm, setSendingForm } = useSendingForm()
provide(setSendingFormKey, { setSendingForm })
</script>

<template>
  <YscModal @close="$emit('close')">
    <template #title>
      <template v-if="name">
        {{ $t('components.app_modal_wishlist_update.title_dynamic', { name_string: name }) }}
      </template>
      <template v-else>
        {{ $t('components.app_modal_wishlist_create.title') }}
      </template>
    </template>

    <YscForm
      id="wishlist-edition-form"
      :submit-action="() => submitAction({ form })"
    >
      <YscInputKeyboard
        id="wishlist-name"
        v-model="form.name"
        type="text"
        required
        autofocus
      >
        {{ $t('data.wishlist.name.label') }}
        <template #description>
          {{ $t('data.wishlist.name.description') }}
        </template>
      </YscInputKeyboard>

      <YscInputRadioGroup
        id="is_public"
        v-model="form.is_public"
        :options="isPublicOptions"
        name="is_public"
        required
      >
        {{ $t('data.wishlist.is_public.label') }}
      </YscInputRadioGroup>
    </YscForm>

    <template #footer>
      <YscButtonSubmit
        form="wishlist-edition-form"
        :loading="sendingForm"
      >
        {{ name ? $t('commons.actions.update') : $t('commons.actions.create_wishlist') }}
      </YscButtonSubmit>
    </template>
  </YscModal>
</template>
